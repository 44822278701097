// Abstract
@use "./abstracts/mixins" as *;
@use "./abstracts/tokens" as *;
@use "./abstracts/variables" as *;

//Base
@use "./base/reset" as *;
@use "./base/typography" as *;

.bold {
  font-weight: 700;
}
